import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  background: var(--footer-color);
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  font-family: var(--main-font);
  min-height: 30vh;
  h1 {
    font-family: var(--main-font);
    font-size: 1.6rem;
  }
  // position: fixed;
  left: 0;
  bottom: 0;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  text-align: left;
  margin: 30px 50px;
  flex-direction: column;
  width: 30%;
  padding: 15px;
  padding-left: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
    padding-bottom: 10px;
  }
  h2 {
    padding-top: 10px;
    font-size: 0.9em;
    color: #dcdcdc;
  }
`

const Flink = styled(Link)`
  font-family: var(--body-font);
  margin: 5px;
  letter-spacing: 1px;
  color: #dcdcdc;
  text-decoration: none;
  font-size: 0.8em;
  max-width: max-content;
  &:hover {
    color: #ffc467;
    transition: color 1.5s ease;
  }
`
const Foot = styled.div`
  font-size: 0.6rem;
  background: var(--footer-color);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  vertical-align: middle;
  padding: 5px;
  padding-left: 5%;
  letter-spacing: 1px;
  color: white;
  text-decoration: none;

  @media (max-width: 468px) {
    justify-content: center;
  }
  p {
    font-size: 1em;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 1.2em;
      margin-top: 20px;
      padding: 0px;
    }
  }
`
const FooterTitle = styled.a`
  margin: 0 auto;
  font-weight: 900;
  background: -webkit-linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-left: 2px;
  padding-top: 5px;
  max-width: 80%;
  text-transform: uppercase;
`

const Footer = () => {
  return (
    <>
      <Container>
        <Foot>
          <p>
            © {new Date().getFullYear()}, All rights reserved
            {` `}
            <FooterTitle href="https://www.compoundyourself.com">
              CompoundYourself
            </FooterTitle>
          </p>
        </Foot>
        <Col>
          {/* <Flink to="/">Search</Flink> */}
          <h2>Navigation</h2>
          <Flink to="/">Home</Flink>
          <Flink to="/blog">Blog</Flink>
          <Flink to="/about">About</Flink>
          <Flink to="/categories">Categories</Flink>
          {/*           <Flink to="/">Disclaimer</Flink>
           */}
        </Col>
        <Col>
          <h2>Top Blogs</h2>
          <Flink to="/courses/aaron-sorkin/">
            Aaron Sorkin teaches Screenwriting
          </Flink>
          <Flink to="/courses/phil-ivey/">
            Phil Ivey teaches Poker Strategy
          </Flink>
          <Flink to="/courses/gordon-ramsay/">
            Gordon Ramsay on cooking delicious meals with quality ingredients
          </Flink>
        </Col>

        <Col>
          <h2>MasterClasses Reviews</h2>
          <Flink to="/courses/thomas-keller">Thomas Keller</Flink>
        </Col>
      </Container>
    </>
  )
}

export default Footer
