import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Nav from "./Nav"
import DesktopMenu from "./DesktopMenu"
import Menu from "./Menu"

export const Wrap = styled.div`
  display: flex;
  background: #252525;
  max-width: 100%;
  overflow: hidden;
  @media (min-width: 468px) {
    min-height: 11vh;
    background: #141414;
    margin-bottom: 20px;
  }
`

export const SDiv = styled.div`
  background: var(--main-color);
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 1.45rem 1.0875rem;
  justify-content: space-between;
  align-items: center;
`

export const SLink = styled(Link)`
  font-family: "Jost", sans-serif;
  font-weight: 900;
  font-size: 1.4rem;
  letter-spacing: 2px;
  color: white;
  text-decoration: none;
  // font-style: italic;
  background-color: var(--main-color);
  padding: 8px 15px 1px 2vw;
  @media (min-width: 300px) and (max-width: 600px) {
    font-size: 1.1rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 2rem;
  }
`
const Desktop = styled.div`
  @media (min-width: 1280px) {
    display: none;
  }
`

const Spacer = styled.div`
  width: 50vw;
`

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isNavOpen, setIsNavOpen] = useState(false)
  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: -300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 0.3,
          delay: 0.1,
        }}
      >
        <Wrap>
          <SDiv siteTitle={data.site.siteMetadata.title}>
            <Desktop>
              <Menu onClick={() => setIsNavOpen(true)} />
            </Desktop>
            <SLink to="/">{siteTitle}COMPOUNDYOURSELF</SLink>
            <Nav isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
            <Spacer />
            <DesktopMenu />
          </SDiv>
        </Wrap>
      </motion.div>
    </AnimatePresence>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
