import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { Link } from "gatsby"

const variants = {
  open: { opacity: 1, x: 0 },
  closed: {
    opacity: 0,
    x: "-100%",
  },
}

const ulVariants = {
  open: {
    scale: 1.0,
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.2,
      staggerDirection: 1,
      when: "afterChildren",
    },
  },
  closed: {
    scale: 1,
  },
}

const liVariants = {
  open: {
    opacity: 1,
    y: 0,
  },
  closed: { opacity: 0, y: -20 },
}

const Nav = ({ isNavOpen, setIsNavOpen }) => {
  return (
    <MenuNav
      variants={variants}
      initial="closed"
      animate={isNavOpen ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      <Wrap>
        <Btn onClick={() => setIsNavOpen(false)}>Close</Btn>
        <motion.ul variants={ulVariants}>
          <motion.li variants={liVariants}>
            <Link to="/">Home</Link>
          </motion.li>
          <motion.li variants={liVariants}>
            <Link to="/about">About</Link>
          </motion.li>
          <motion.li variants={liVariants}>
            <Link to="/categories">Categories</Link>
          </motion.li>
        </motion.ul>
      </Wrap>
    </MenuNav>
  )
}

const MenuNav = styled(motion.nav)`
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 40px;
  cursor: pointer;
  background: #252525;
  border: none;
  opacity: 80%;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 3rem;
    padding: 0;
    margin: 0 0 1.5rem;
    a {
      font-family: var(--main-font);
      font-size: 3rem;
      color: white;
      text-decoration: none;
      border-bottom: 2px transparent solid yellow;
      &:hover {
        border-bottom: 2px #ffc467 solid;
      }
    }
  }
`

const Btn = styled.button`
  color: #ffc467;
  margin: 10px 0px 25px -10px;
  text-decoration: none;
  font-family: var(--main-font);
  font-size: 1.8em;
  border: 0;
  background: none;
  cursor: pointer;
  max-width: 150px;
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    font-size: 2.5rem;
  }
`

export default Nav
