import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const DesktopMenu = () => {
  return (
    <MenuNav>
      <Wrap>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/categories">Categories</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </Wrap>
    </MenuNav>
  )
}

const MenuNav = styled.div`
  z-index: 101;
  min-width: 30vw;
  padding: 15px;
  cursor: pointer;
  background: var(--main-color);
  border: none;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 4px;
  }
  li {
    display: inline;
    a {
      font-size: 0.9rem;
      margin-left: 35px;
      color: white;
      text-decoration: none;
      &:hover {
        border-bottom: 2px #ffc467 solid;
      }
    }
  }
  @media (max-width: 1280px) {
    display: none;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default DesktopMenu
